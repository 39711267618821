import { Link } from "react-router-dom";

const Image = ({ src, alt, ...rest }) => (
	<picture {...rest}>
		<source type="image/webp" srcSet={`/static/assets/images/${src}.png`} />
		<img src={`/static/assets/images/${src}.png`} alt={alt} {...rest} />
	</picture>
);

const Topnav = () => {
	return (
		<section className="top-nav">
			<aside className="nav-wrap">
				<Link to="#" className="logo-wrap">
					<Image
						src={"afriexnewlogo"}
						alt={"Afriex logo"}
						className="login-logo"
					/>
				</Link>
			</aside>
		</section>
	);
};

export default Topnav;
